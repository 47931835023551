import { useEffect, useRef, useState, useCallback } from 'react';

//next
// import Link from 'next/link';
import Image from 'next/image';

import styles from 'styles/components/header.module.scss';

//material
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';


//3rd party
// import { isMobileOnly} from "react-device-detect";
import { isMobileOnly } from 'react-device-detect';
// import ReactPlayer from 'react-player';
import ReactPlayer from 'react-player/youtube'
import { motion } from "framer-motion";

//assets
// import bannerPoster from 'assets/Pictures/shine/poster.webp';
import IconArrowDown from 'assets/Pictures/icon-arrow-down.svg';

const variants = {
  open: { opacity: 1, y:0, pointerEvents:'all' },
  closed: { opacity: 0, y:20, pointerEvents:'none' },
}

function HeaderComponent({title,titleW,titleH,collection,youtube,posterdesktop,postermobile,isimage,bgvideo,props,titleText}){

  const isMobile = isMobileOnly;

  const [videoH, setvideoH] = useState('');
  useEffect(() => {
    setvideoH((360 / 640) * window.innerWidth);
    // console.log("new h = "+videoH)
  }, []);
  
  const [isImage, setIsImage] = useState(false);
  const [titleclass, settitleclass] = useState("video_title");

  const [isOpenVideo, setIsOpenVideo] = useState(false);

  const playerOnPlay = () => {
    console.log("playerOnPlay")
    setIsOpenVideo(true)
  };
  const playerOnPuase = () => {
    console.log("playerOnPuase");
    setTimeout(() => {
      setplayerPlaying(false);
      setIsOpenVideo(false)
    }, 1000);
    
  };
  const playerOnEnded = () => {
    console.log("playerOnEnded");
    setplayerPlaying(false);
    setIsOpenVideo(false)
  };
  const playerOnReady = () => {
    // console.log("playerOnReady");
  };

  const [playerPlaying, setplayerPlaying] = useState(false);
  const btn_play = () => {
    setplayerPlaying(true);
    setIsOpenVideo(true)
    // setIsOpenVideo(isOpenVideo => !isOpenVideo)
  };

  const scrollDown = () => {
    document.getElementById('the-lifeshine-collection').scrollIntoView({
      behavior: 'smooth'
    });
  };

  const [posterSrc, setposterSrc] = useState();
  useEffect(() => {      
    setposterSrc(isMobile ? postermobile : posterdesktop);
    setIsImage(isimage == "1" ? true : false);
    settitleclass(isimage == "1" ? "image_title" : "video_title");
  }, []);

  return(
    <div id="the-collection" className={`${styles.article} article`} >
     
      <Container maxWidth={false} disableGutters={true}>
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={0} component="figure" className="panel" sx={{
          m:0
        }}>
          <Grid item xs={12} >
            <div className={`${styles.content_wr} content_wr`} >
              <div className={`${styles.video_wr} video_wr`} style={{height: videoH + 'px'}} >
                { isImage == true && 
                  <img src={posterSrc} layout="responsive" className="img_fluid"/> 
                }
                { isImage == false && 
                  <video autoPlay loop muted playsInline className="video_banner" poster={posterSrc} suppressHydrationWarning={true}>
                    <source src={`/assets/Pictures/${collection}/header-video.mp4`} type="video/mp4"/>
                  </video>
                }
              </div>
              
              <div className={`${styles.context_wr} context_wr`}>
                <div className={`${styles.context} context`}>
                  { isImage == false && 
                  <div className={`${styles.title_wr} title_wr`}>
                    <Image src={title} width={titleW} height={titleH} layout="responsive" className="img_fluid" alt={collection} priority/>                     
                  </div>
                  }
                  { isImage == true && 
                    <Typography variant="h2" gutterBottom component="h1" align="center" className='pageTitle'>
                      {titleText}
                    </Typography>
                  }
                  { isImage == false && 
                    <IconButton aria-label="play" className={`${styles.btn_play} btn_play`} onClick={btn_play}>
                      <PlayArrowIcon fontSize="inherit" />
                    </IconButton>
                  }
                    <IconButton aria-label="scroll" className={`${styles.btn_scroll} btn_scroll`} onClick={scrollDown}>
                      <IconArrowDown fontSize="inherit" />
                    </IconButton>
                </div>
              </div>

              <motion.div className={`${styles.motion_div} motion_div`} 
                animate={isOpenVideo ? "open" : "closed"}
                variants={variants}
              >
                <ReactPlayer
                  onPlay={playerOnPlay}
                  // onPause={playerOnPuase}
                  onEnded={playerOnEnded}
                  onReady={playerOnReady}
                  playing={playerPlaying}
                  playsinline={true}
                  controls={true}
                  className={`${styles.react_player} react_player`}
                  url={youtube}
                  width='100%'
                  height='100%'
                  config={{
                    youtube: {
                      playerVars: { showinfo: 0 }
                    },
                  }}
                />
              </motion.div>
            </div>

            
            
          </Grid>
        </Grid>
      </Container>
      
    </div>
  )
}
export default HeaderComponent;