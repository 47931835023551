// import * as React from 'react';
// import { useEffect, useRef, useState, useCallback } from 'react';

import Link from 'next/link'
import { useRouter } from 'next/router';

import styles from 'styles/footer.module.scss';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import Logo from 'assets/Pictures/logo.svg';

// import { isMobileOnly } from "react-device-detect";


import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import LinkedInIcon from '@mui/icons-material/LinkedIn';

import Paper from '@mui/material/Paper';
import { useTranslation } from 'next-i18next';

function FooterComponent({}){
  // const isMobile = isMobileOnly;

  const { t } = useTranslation('common');

  const router = useRouter();
  // const { locale } = router;

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth' // for smoothly scrolling
  //   });
  // };

  return(
    <>
      
      <Paper className={styles.footer} component="footer" className={styles.footer}
      sx={{
        bgcolor:"#F1F1F1",
        color:"#000",
        "& .MuiListItemIcon-root":{color:"#000"}
      }}>
        <Container maxWidth='lg' disableGutters={false} className={styles.footer_container}>
          <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={0} component="section" className={styles.grid_container}>

            <Grid item xs={12} className={styles.grid_logo}>
              <div className={styles.contents_logo}>
                <Link href={router.pathname.includes("hair-stylist") ? "/hair-stylist":"/"} className={`${styles.link}`}>
                  <span className={styles.logo_wr}>
                    <Logo className={styles.logo}/>
                  </span>
                </Link>
              </div>
            </Grid>

            <Grid item xs={12} md={4} className={styles.grid_info}>
              <h1 className={styles.title}>
                <span>{t('explore_other')}</span>
                <Link href="https://www.jotunprofessionals.com/me/en/b2b" scroll={false}>
                  <a target="_blank" rel="noopener noreferrer">
                    {t('prod_collections')}
                  </a>
                </Link>
              </h1>
              <p className={styles.desc}>
                <span>{t('follow_us')}</span>
                <Link href="https://www.linkedin.com/company/jotunmeia/" passHref >
                  <a target="_blank" rel="noopener noreferrer">
                  <IconButton aria-label="facebook" target="_blank" >
                    <LinkedInIcon fontSize="large"/>
                  </IconButton>
                  </a>
                </Link>
              </p>
            </Grid>

            <Grid item xs={12} md={4} className={styles.grid_sitemap_a}>
              <List sx={{ pt:0,pb:2 }} component="nav" dense={true} className={styles.list}>

                <Link href="https://www.jotun.com/me-en/decorative" passHref scroll={false}>
                  <a target="_blank" rel="noopener noreferrer">
                    <ListItemButton sx={{ px: 3,mt:2 }}>
                      <ListItemText primary={t('for_deco')} />
                    </ListItemButton>
                  </a>
                </Link>
                <Link href="https://www.jotun.com/ww-en/about-jotun/" passHref scroll={false}>
                  <a target="_blank" rel="noopener noreferrer">
                    <ListItemButton sx={{ px: 3 }}>
                      <ListItemText primary={t('jotun_global')} />
                    </ListItemButton>
                  </a>
                </Link>
                <Link href="https://www.jotun.com/me-en/decorative/dealer-search/" passHref >
                  <a target="_blank" rel="noopener noreferrer">
                    <ListItemButton sx={{ px: 3 }}>
                      <ListItemText primary={t('jotun_dealers')} />
                    </ListItemButton>
                  </a>
                </Link>
                
              </List>
            </Grid>

            <Grid item xs={12} md={4} className={styles.grid_sitemap_b}>
              <List sx={{ pt:0,pb:2 }} component="nav" dense={true} className={styles.list}>

                <Link href="https://www.jotun.com/ww-en/about-jotun/legal/privacy-statement/" passHref scroll={false}>
                  <a target="_blank" rel="noopener noreferrer">
                    <ListItemButton sx={{ px: 3,mt:2 }}>
                      <ListItemText primary={t('privacy_policy')} />
                    </ListItemButton>
                  </a>
                </Link>
                <Link href="https://www.jotun.com/ww-en/about-jotun/legal/terms-and-conditions/" passHref scroll={false}>
                  <a target="_blank" rel="noopener noreferrer">
                    <ListItemButton sx={{ px: 3 }}>
                      <ListItemText primary={t('terms_conditions')} />
                    </ListItemButton>
                  </a>
                </Link>
                <Link href="https://www.jotun.com/ww-en/about-jotun/legal/cookie-policy/" passHref scroll={false}>
                  <a target="_blank" rel="noopener noreferrer">
                    <ListItemButton sx={{ px: 3 }}>
                      <ListItemText primary={t('cookie_policy')} />
                    </ListItemButton>
                  </a>
                </Link>
                {/* <Link href={'/cookie-policy/'} passHref >
                  <ListItemButton sx={{ px: 3 }}>
                    <ListItemText primary={t('cookie_settings')} />
                  </ListItemButton>
                </Link> */}
                
              </List>
            </Grid>

            <Grid item xs={12} className={styles.grid_copyright}>
              <p className={styles.copyright}>
                <small>{t('all_rights')}</small>
              </p>
            </Grid>

          </Grid>
        </Container>
      
      </Paper>
      
    </>
  )
}
export default FooterComponent;